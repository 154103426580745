import { VideoFile } from './VideoFile';
import './App.css';

export default function Video(props: VideoFile, showNames: boolean) {
	return (
		<div key={props.name} className="Video">
			<video src={props.source} width="750" height="500" controls></video>
			<div>{showNames ? props.name : '? fps'}</div>
		</div>
	);
}
