import React, { useState } from 'react';
import './App.css';
import Video from './Video';
import { VideoFile } from './VideoFile';

const videoFiles: VideoFile[] = [
	{
		name: '24fps',
		source:
			'https://objectstorage.us-sanjose-1.oraclecloud.com/p/VELCS8s_iBedScI5zdFU7X1zP5jIDKHce7fw6X9pI4TxmVwo8skULbqeEv1hRxYf/n/axnbrvylznci/b/video-survey-bucket/o/24.mp4',
	},
	{
		name: '48fps',
		source:
			'https://objectstorage.us-sanjose-1.oraclecloud.com/p/e93atrIB3-b1jngXoFV8HSC2zrfSOQJsO8QHZbyJ7L3KNN4LaP0Nku66hs4BcHts/n/axnbrvylznci/b/video-survey-bucket/o/48.mp4',
	},
	{
		name: '60fps',
		source:
			'https://objectstorage.us-sanjose-1.oraclecloud.com/p/yD71fXJZ7cHAy7MLgNHFovpR_BtFw_ZoTvGYLeo3Eey5rznBcmc9SvoLJvyYEJsh/n/axnbrvylznci/b/video-survey-bucket/o/60.mp4',
	},
];

function shuffle(initial: VideoFile[]) {
	const values = initial.slice();
	let index = values.length,
		randomIndex;
	while (index !== 0) {
		randomIndex = Math.floor(Math.random() * index);
		index--;
		[values[index], values[randomIndex]] = [values[randomIndex], values[index]];
	}

	return values;
}

function App() {
	const [videos, setVideos] = useState(shuffle(videoFiles));
	const [showNames, setShowNames] = useState(false);
	const listItems = videos.map((v) => Video(v, showNames));
	return (
		<div className="App">
			<p>{listItems}</p>
			<button
				type="button"
				onClick={() => {
					setShowNames(false);
					setVideos(shuffle(videoFiles));
				}}
			>
				Shuffle
			</button>
			<button
				disabled={showNames}
				type="button"
				onClick={() => {
					setShowNames(true);
				}}
			>
				Reveal
			</button>
		</div>
	);
}

export default App;
